import React, { useEffect, useState } from "react";
import { Header, Checkbox, Button } from "semantic-ui-react";
// import { connect } from "react-redux";
import Notificationsettings from "./Notificationstable";
import {
  getBusinessProfile,
  createBusinessProfile,
  updateBusinessProfile,
} from "../../../../actions/businessclient";
import { Alert } from "rsuite";
import SequencesTable from "./SequencesTable";
import BillingTable from "./BillingTable";
import Storagesettings from "./Storagesettings";
// import Documents from './documents'

import Reveal from "../../../../components/reveal";
import ShipFromSettings from "./Shipfromsettings";

function ProfileSettings(props) {

  const { bclientId, businessclient } = props;
  const [settings, setProfile] = useState({});

  useEffect(() => {
    getBusinessProfile(bclientId).then((resp) => setProfile(resp));
  }, [bclientId]);

  const handleCreateProfileSettings = () => {
    createBusinessProfile(bclientId).then((data) => setProfile(data));
  };

  const handleBusinessProfileUpdate = (e, { name, checked }) => {
    updateBusinessProfile({
      id: bclientId,
      update: { [name]: checked },
    }).then((data) => {
      setProfile(data);
      Alert.success("Updated");
    });
  };

  if (!settings || !businessclient._id) return <div>Loading...</div>;

  if (!(settings && settings._id))
    return (
      <section className="profile-settings">
        <Header as="h3">No profile settings saved yet</Header>
        <Button onClick={handleCreateProfileSettings}>Add settings</Button>
      </section>
    );

  return (
    <section className="profile-settings">
      <Header as="h3">Business client profile settings</Header>
      <Reveal
        header={"Orders settings"}
        subHeader={"Invoice generation, goods blocking, courier assignment"}
        body={
          <ul className="settings-list">
            <li>
              <Checkbox
                label="Generate invoice automatically"
                toggle
                name="orders.invoicing.autoGenerate"
                checked={settings.orders.invoicing.autoGenerate}
                onChange={handleBusinessProfileUpdate}
              />
              <div className="flex--column">
                <Checkbox
                  toggle
                  label="Generate when DELIVERD"
                  name="orders.invoicing.generate_when_delivered"
                  checked={settings.orders.invoicing.generate_when_delivered}
                  onChange={handleBusinessProfileUpdate}
                />
                <Checkbox
                  toggle
                  label="Generate when Shipped"

                  name="orders.invoicing.generate_when_shipped"
                  checked={settings.orders.invoicing.generate_when_shipped}
                  onChange={handleBusinessProfileUpdate}
                />
              </div>
            </li>
            <li>
              <Checkbox
                toggle
                name="orders.invoicing.autoPrint"
                checked={settings.orders.invoicing.autoPrint}
                onChange={handleBusinessProfileUpdate}
              />
              Print invoice
            </li>
            <li>
              <Checkbox
                toggle
                name="orders.automaticBlockingGoods"
                checked={settings.orders.automaticBlockingGoods}
                onChange={handleBusinessProfileUpdate}
              />
              Automatically block goods
            </li>
            <li>
              <Checkbox
                toggle
                name="orders.shipping.automaticAssingCourier"
                checked={settings.orders.shipping.automaticAssingCourier}
                onChange={handleBusinessProfileUpdate}
              />
              Automatically assign courier
            </li>
          </ul>
        }
      />

      <Reveal
        header={"Billing"}
        open={true}
        subHeader={"Change days to due and billing cycle"}
        body={
          <div>
            <BillingTable
              bclientId={bclientId}
              profileId={settings._id}
              billing={settings.billing}
              businessclient={businessclient}
            />
          </div>
        }
      />
      <Reveal
        header={"Storage settings"}
        open={true}
        subHeader={"Type of storage for billing purposes"}
        body={
          <div>
            <Storagesettings bclientId={bclientId}></Storagesettings>
          </div>
        }
      />
      <Reveal
        header={"Ship From settings"}
        open={true}
        subHeader={"Country supply warehouse"}
        body={
          <div>
            <ShipFromSettings bclientId={bclientId}></ShipFromSettings>
          </div>
        }
      />

      <Reveal
        header={"Sequences"}
        subHeader={"Change days to due and billing cycle"}
        body={<SequencesTable bclientId={bclientId} profileId={settings._id} />}
      />

      <Header as="h4">Notifications</Header>

      <Reveal
        open={true}
        header={"Email notifications"}
        subHeader={"Choose to recieve email notifications or not"}
        body={
          <div>
            <Notificationsettings businessclient={businessclient._id} />
          </div>
        }
      />

      <Reveal
        header={"Dashboard notifications"}
        subHeader={"Choose what kind of notifications to see on your dashboard"}
        body={
          <ul className="settings-list">
            <li>
              <Checkbox
                toggle
                name="notifications.dashboard.tickets.newticket"
                checked={
                  settings.notifications.dashboard &&
                  settings.notifications.dashboard.tickets.newticket
                }
                onChange={handleBusinessProfileUpdate}
              />
              New Purchase Order
            </li>
            <li>
              <Checkbox
                toggle
                name="notifications.dashboard.tickets.newmessage"
                checked={
                  settings.notifications.dashboard &&
                  settings.notifications.dashboard.tickets.newmessage
                }
                onChange={handleBusinessProfileUpdate}
              />
              Shipping Purchase Order
            </li>
          </ul>
        }
      />

      {/* <Documents></Documents> */}
    </section>
  );
}

// const mapStateToProps = reduxState => ({
//   settings: reduxState.businessclients.openClient.settings
// });

export default ProfileSettings;
