import React, { useState, useRef } from "react";
import { Comment, Form, Button,  Loader } from "semantic-ui-react";
import Moment from "react-moment";
import { connect } from "react-redux";

import { addComment, addReply } from "../../../actions/ticket";
import Avatar from "../../../avatar.png";
import { useEffect } from "react";

function Comments(props) {
  const {
    comments = [],
    addComment,
    addReply,
    ticketId,
    status,
    isLoading
  } = props;

  const [replyOpen, setReplyOpen] = useState(null); // id
  const [comment, setComment] = useState("");

  const handleCommentChange = (e, { value }) => {
    setComment(value);
  };
  const handleAddComment = e => {
    e.preventDefault();
    addComment({ id: ticketId, comment: { text: comment } });
    setComment("");
    const wrapper = document.querySelector(".comments-wrapper");
    wrapper.scrollBy({ top: wrapper.scrollHeight + 100 });
  };

  // Scroll to the bottom of comment section
  const previousComments = useRef([]);
  useEffect(() => {
    const wrapper = document.querySelector(".comments-wrapper");
    if (!previousComments.current.length && comments.length)
      // if (previousComments.current.length !== comments.length)
      wrapper.scrollBy({ top: wrapper.scrollHeight });

    previousComments.current = comments;
  }, [comments]);

  // if (isLoading) {
  //   return (
  //     <div
  //       className="comments-wrapper"
  //       style={{ paddingTop: "20vh", paddingBottom: "20vh" }}
  //     >
  //       <Loader active inline="centered"  />
  //     </div>
  //   );
  // }

  return (
    <>
      {/* {comments.length > 0 ? ( */}
      <div className="comments-wrapper">
        <Comment.Group>
          {comments.map(comment => (
            <CommentItem
              {...comment}
              // withReply={false}
              key={comment._id}
              setReplyOpen={setReplyOpen}
              replyOpen={replyOpen}
              addReply={addReply}
              ticketId={ticketId}
              status={status}
            />
          ))}
        </Comment.Group>

        <div style={{ paddingTop: "10px", paddingBottom: "30px" }}>
          {isLoading && <Loader active inline="centered" size="small" />}
        </div>
      </div>
      {/* ) : (
        <Header
          as="h4"
          color="grey"
          textAlign="center"
          content="No comments found"
        />
      )} */}

      {status !== "Closed" && (
        <Form className="ticket-add-comment">
          <Form.TextArea
            className="ticket-add-comment__textarea"
            placeholder="Type your comment here ..."
            value={comment}
            onChange={handleCommentChange}
          />
          <div className="flex--right mt--1">
            <Button content="Add comment" primary onClick={handleAddComment} />
          </div>
        </Form>
      )}
    </>
  );
}

const mapStateToProps = reduxState => ({
  isLoading: reduxState.tickets.isCommentLoading
});

export default connect(mapStateToProps, { addComment, addReply })(Comments);

// CommentItem
const CommentItem = ({
  _id,
  username,
  text,
  date,
  createdAt,
  replies = [],
  ticketId,
  addReply,
  withReply = true,
  setReplyOpen,
  replyOpen,
  status
}) => {
  const [reply, setReply] = useState("");

  const handleReplyChange = (e, { value }) => {
    setReply(value);
  };
  const handleAddReply = e => {
    e.preventDefault();
    addReply({ ticketId, commentId: _id, reply: { text: reply } });
    setReply("");
    setReplyOpen(null);
  };

  return (
    <div className="ticket-comment">
      <img className="comment__avatar" src={Avatar} alt="user profile avatar" />
      <div className="comment__conntent">
        <span className="comment__author bold--text">{username}</span>
        <span className="comment__metadata grey--text small--text">
          <Moment format="DD-MM-YY HH:mm">{createdAt}</Moment>
        </span>
        <p className="comment__text">{text}</p>
        {withReply && status !== "Closed" && (
          <div className="comment__actions">
            <button
              className="comment__action"
              onClick={() => setReplyOpen(_id)}
            >
              Reply
            </button>
          </div>
        )}
        {replyOpen === _id && (
          <Form>
            <Form.TextArea
              placeholder="Reply to the comment ..."
              value={reply}
              onChange={handleReplyChange}
            />
            <Button
              content="Cancel"
              onClick={() => setReplyOpen(null)}
              size="tiny"
              compact
            />
            <Button
              content="Add Reply"
              primary
              compact
              size="tiny"
              onClick={handleAddReply}
            />
          </Form>
        )}
        {replies && replies.length > 0 && (
          <Comment.Group>
            {replies.map(reply => (
              <CommentItem {...reply} withReply={false} key={reply._id} />
            ))}
          </Comment.Group>
        )}
      </div>
    </div>
  );
};
