import React, { useState, useEffect } from "react";
import {
  Segment,
  Header,
  Label,
  Checkbox,
  Dimmer,
  Loader,
  
} from "semantic-ui-react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import {SelectPicker} from 'rsuite'


export const statusColor = {
  Open: "green",
  Closed: "yellow",
};

export const priorityColor = {
  High: "red",
  Medium: "yellow",
  Low: "green",
};

const breakpoint = 600;

function Table(props) {
  const { tickets = [], isLoading,updateMany,users=[],filters} = props;
  const [windowWidth, setWidth] = useState(null);

  const setWithFunct = () => {
    const width = window.innerWidth;
    setWidth(width);
  };

  const [selected,setSelected]=useState([])
  const [selectAll,setSelectAll]=useState(false)

  const handleSelected=(_id)=>{
    
    if(selected.includes(_id)) {
      
      const position = selected.indexOf(_id)
      if(position>=0)
                {
                 selected.splice(position,1)
                  setSelected([...selected])
                }

      
    }else setSelected([...selected,_id])

  }

  const handleSelectAll =()=>{
    if(!selectAll) {
      const ids=tickets.map(item=>item._id)
      setSelectAll(true)
      setSelected([...ids])
    }else {
      setSelected([])
            setSelectAll(false)

    }
  }

  const handleAction =(query)=>{
    setSelected([])
    setSelectAll(false)

    updateMany({ids:selected,query,filters})
  }


  useEffect(() => {
    setWithFunct();
    window.addEventListener("resize", setWithFunct);
    return () => window.removeEventListener("resize", setWithFunct);
  });

  if (isLoading)
    return (
      <div
        style={{ position: "relative", height: "40vh" }}
        className="ticket-list"
      >
        <Dimmer active inverted>
          <Loader inverted size="medium">
            Loading
          </Loader>
        </Dimmer>
      </div>
    )

  if (!tickets.length)
    return (
      <Segment padded style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <Header as="h3" textAlign="center" color="grey">
          No Tichets Found
        </Header>
      </Segment>
    )

    return (
      <ul className="ticket-list">
      
     <div className='flex--between'>
      <Checkbox 
      label='Select All'
      checked={selectAll}
      onChange={(e,{checked})=>handleSelectAll()}
      />
      
      <SelectPicker 
          value={''}
          onChange={(value)=>{handleAction({assignedTo:value})}}

          disabled={!selected.length>0}
          placeholder={`${selected.length>0?selected.length:''} Assign user`}
          data={
              users.map(user=>({
                    label:user.value,
                    value:user._id,
                    role:"master"
              }))
          } 
          style={{ width: 224 }} />
      
      </div>

      {tickets.map((ticket) => (
        <li key={ticket._id}>
          {windowWidth > breakpoint ? (
            <TicketDesktop {...ticket} handleSelected={handleSelected} selected={selected}  />
          ) : (
            <TicketMobile {...ticket} handleSelected={handleSelected} selected={selected} />
          )}
        </li>
      ))}
    </ul>
     )
}

export default Table;

// Desktop Component
const TicketDesktop = ({

  description,
  _id = "",
  createdAt,
  updatedAt,
  status,
  category = {},
  priority,
  subcategory,
  openBy = {},
  assignedTo = {username:null},
  comments = [],
  callcenter=null,
  selected,
  handleSelected,
  users
}) => {


  const callcenterClientInfo =<div>
                                 <h5>{callcenter &&callcenter.client.web}</h5>
                                 <p>{callcenter &&callcenter.client.name}</p>
                                 <p>{callcenter &&callcenter.client.phone}</p>
                                 <p>{callcenter &&callcenter.client.email}</p>
                                 <p>{callcenter &&callcenter.client.position}</p>
  </div>
                                 
                           
  return(
  <div>

    <Checkbox 
      checked={selected.includes(_id)}
      onChange={(e,{checked})=>handleSelected(_id)}
      />

  <Link to={`/tickets/${_id}`}>
    <Segment
    // color={priorityColor[priority]}
    >
      <div className="ticket">

        <div className="ticket_category-col">
          <div className="ticket_subgrid mb--1">
            <span className="small--text grey--text">priority: </span>
            <Label
              size="mini"
              color={priorityColor[priority]}
              horizontal
              // style={{ display: "inline-block", marginBottom: "0.3em" }}
            >
              {priority}
            </Label>
          </div>
          <div className="ticket_subgrid">
            <span className="small--text grey--text">categ. </span>
            <Label
              size="large"
              // style={{ display: "inline-block", marginBottom: "0.3em" }}
            >
              {category.name}
            </Label>
            {/* <br /> */}
            <span className="small--text grey--text">subcat. </span>
            {/* <Label basic size="mini"> */}
            <span className="bold--text grey--text small--text">
              {subcategory}
            </span>
            {/* </Label> */}
          </div>
        </div>

        <div className="ticket_description-col">
          {/* <div className="flex--between vertical--top"> */}
         {description&&<div>
                          <h6>Description</h6>
                          <p className="dark-grey--text">
                            {description && description.length > 100
                              ? description.substring(0, 100) + "... "
                              : description}
                          </p>
                        </div>}
          <p>{callcenter&&callcenterClientInfo}</p>
        </div>

        <div className="ticket_assigned-col">
          <>
          {callcenter.callLater &&   <div>
              <span className="small--text grey--text">Call later:</span>{" "}
               <Moment format="DD-MM-YY HH:mm">{callcenter.callLater}</Moment>
            </div>}
            
            <span className="grey--text small--text">open by: </span>
            <span className="dark-grey--text">{openBy.username}</span>
            <br />
            <span className="small--text grey--text">assigned to: </span>
            <span className="dark-grey--text">{assignedTo?assignedTo.username:null}</span>
            <br />
            <br />
            <span className="small--text grey--text">Last comment:</span>{" "}
            <br></br>
            <span className="dark-grey--text">{comments.length>0&&comments[comments.length-1].text}</span>
            <br></br>
            
   
          </>
        </div>

        <div className="ticket_status-col">
          <span className="small--text grey--text mt--1">
            created at:
            {/* <br /> */}
            <Moment format="DD-MM-YY HH:mm" className="bold--text ml--1">
              {createdAt}
            </Moment>
          </span>
          <br />
          <span className="small--text grey--text">
            updated at:
            {/* <br /> */}
            <Moment format="DD-MM-YY HH:mm" className="bold--text ml--1">
              {updatedAt}
            </Moment>
          </span>
        </div>

        <div className="ticket_status-col">
          <Label
            size="small"
            basic
            color={statusColor[status]}
            style={{ display: "inline-block", marginBottom: "0.5em" }}
          >
            {status}
          </Label>
        </div>
      </div>
    </Segment>
  </Link>


 
  </div>
)};

// Mobile Component
const TicketMobile = ({
  
  description,
  _id = "",
  createdAt,
  updatedAt,
  status,
  category = {},
  priority,
  subcategory,
  openBy = {},
  assignedTo = {username:''},
  comments = [],
}) => (
  <Segment
  // color={priorityColor[priority]}
  >
    <div className="ticket-mobile">
      <div className="flex--between">
        <div>
          
          <Label
            size="mini"
            color={priorityColor[priority]}
            horizontal
            // style={{ display: "inline-block", marginBottom: "0.3em" }}
          >
            {priority}
          </Label>
        

        </div>

        <Label
          size="small"
          basic
          color={statusColor[status]}
          style={{ display: "inline-block", marginBottom: "0.5em" }}
        >
          {status}
        </Label>
      </div>

      {/* Description */}
      
      <div className="mt--1 mb--1">
      
        <p>
          {description && description.length > 100
            ? description.substring(0, 100) + "... "
            : description}
        </p>
      </div>

      <div className="mb--1">
        <Label size="large">{category.name}</Label>
        <span className="bold--text grey--text small--text ml--1">
          {subcategory}
        </span>
      </div>

      <div className="ticket-mobile__grid">
        <div>
          <span className="grey--text small--text mr--1">open by:</span>{" "}
          {openBy.username}
        </div>

        <span className="small--text grey--text mr--1">
          created at:
          <Moment format="DD-MM-YY HH:mm" className="bold--text ml--1">
            {createdAt}
          </Moment>
        </span>

        <div>
          <span className="small--text grey--text">assigned to:</span>{" "}
          {assignedTo?assignedTo.username:null}
        </div>

        <span className="small--text grey--text">
          updated at:
          <Moment format="DD-MM-YY HH:mm" className="bold--text ml--1">
            {updatedAt}
          </Moment>
        </span>

        <div>
          <span className="small--text grey--text">Last Comment:</span>{" "}
          {comments.lenght>0&&comments[comments.length].text}
        </div>
      </div>
    </div>
  </Segment>
  
);
