import React from "react";
import { Icon } from "semantic-ui-react";

const sortMenuItems = [
  { value: "priority", text: "Priority" },
  { value: "createdAt", text: "Most Recent" },
  { value: "updatedAt", text: "Recently Updated" },
];

function Sort({ sortBy, updateFilters }) {
  return (
    <div className="ticket-sort-filters">
      {sortMenuItems.map(({ value, text }, i) => (
        <span
          key={i}
          className={sortBy.includes(value) ? "active-ticket-sort" : ""}
          onClick={() =>
            updateFilters({
              sortBy: sortBy === value + "Up" ? value + "Down" : value + "Up",
              currentPage: 1,
            })
          }
        >
          <Icon
            name={`angle ${sortBy === value + "Up" ? "up" : "down"}`}
            color="grey"
          />
          {text}
        </span>
      ))}
    </div>
  );
}

export default Sort;
