import React, { useState, useEffect } from "react";
import { Icon,  Form, Radio, Button } from "semantic-ui-react";
import Modal from "react-responsive-modal";
// import {Nav} from 'rsuite'
import { useToggleModalNewEdit } from "../../../hooks/useToggleModalNewEdit";
import CategoryModal from "./CategoryModal";

const priorities = ["All", "High", "Medium", "Low"];
const prioritiesColors = {
  All: "grey",
  High: "#9c2424",
  Medium: "#b6bd31", //  "yellow",
  Low: "green",
};

// const statuses=["New","Email","In progress","Offer sent: no respense","Offer sent: rejected","Call later","Not interested","Contract:sent","Contract: signed"]

const defaulInitModalValues = {
  category: "",
  subcategories: [],
  department: "",
};


function Sidebar(props) {
  const {
    priority: selectedPriority,
    handlePriorityChange,
    totals,
    subcategory: selectedSubcategory,
    category: selectedCategory,
    handleCategoryChange,
    canEdit,
    getTicketCategories,
    createTicketCategory,
    updateTicketCategory,
    categories,
  } = props;

  // Toggle menu : open/cloesed
  const [collapsed, setCollapsed] = useState({
                                          Priority: true,
                                          Statuses:true,
                                          Categories: true,
                                        });
  const toggleCollapse = (item) => {
    setCollapsed((prevState) => ({ ...prevState, [item]: !prevState[item] }));
  };

  // Category Modal
  const {
    isModalOpen,
    isEditModeOn,
    initFormValues,
    openModal,
    closeModal,
  } = useToggleModalNewEdit(defaulInitModalValues);
  
  useEffect(() => getTicketCategories(), []);

  return (
    <>
      <div className="ticket-aside">
        {/* Status */}
        <div className="aside-menu">
          <div
            className="aside-menu__header"
            onClick={() => toggleCollapse("Priority")}
          >
            <span className="aside-menu__header-text">Priority</span>
            <Icon
              name={`angle ${collapsed.Priority ? "up" : "down"}`}
              size="large"
              color="grey"
              className="aside-menu__header-toggle"
            />
          </div>

          {collapsed.Priority && (
            <Form className="aside-menu__body">
              {priorities.map((priority, i) => (
                <Form.Field className="aside-menu__item">
                  <Radio
                    label={priority}
                    name="priority"
                    value={priority}
                    checked={selectedPriority === priority}
                    onChange={handlePriorityChange}
                  />
                  <AmountByPriority
                    numberOfOpenTickets={totals.priority[priority]}
                    priority={priority}
                  />
                </Form.Field>
              ))}
            </Form>
          )}
        </div>

        {selectedCategory && (
          <Button
            style={{ marginBottom: "1em" }}
            compact
            size="mini"
            icon="times"
            labelPosition="right"
            content={`${
              categories.find(({ _id }) => _id === selectedCategory).name
            } / ${selectedSubcategory || "All"} `}
            onClick={() =>
              handleCategoryChange({
                category: "",
                subcategory: "",
              })
            }
          />
        )}

        {/* Categories */}
        <div className="aside-menu">
          <div
            className="aside-menu__header"
            onClick={() => toggleCollapse("Categories")}
          >
            <span className="aside-menu__header-text">
              Categories
              {canEdit && (
                <Button
                  compact
                  basic
                  circular
                  size="mini"
                  icon="plus"
                  style={{
                    marginLeft: "1em",
                    boxShadow: "none",
                    position: "relative",
                    zIndex: "10",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openModal(false); //create
                  }}
                />
              )}
            </span>
            <Icon
              name={`angle ${collapsed.Categories ? "up" : "down"}`}
              size="large"
              color="grey"
              className="aside-menu__header-toggle"
            />
          </div>

          {collapsed.Categories &&
            categories.map(({ name, subcategories = [], department, _id }) => (
              <div key={_id}>
                <div
                  className="aside-menu__subheader aside-menu__header"
                  onClick={() => toggleCollapse(name)}
                >
                  <span className="aside-menu__header-text">
                    {name}
                    {canEdit && (
                      <Button
                        compact
                        basic
                        circular
                        size="mini"
                        icon="pencil"
                        style={{ marginLeft: "1em", boxShadow: "none" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          openModal(true, {
                            name,
                            subcategories,
                            department,
                            _id,
                          }); //edit
                        }}
                      />
                    )}
                  </span>

                  <Icon
                    name={`angle ${collapsed[name] ? "up" : "down"}`}
                    size="small"
                    color="grey"
                    className="aside-menu__header-toggle"
                  />
                </div>

                {collapsed[name] && (
                  <Form className="aside-menu__body">
                    {subcategories.length > 0 && (
                      <>
                        <Form.Field className="aside-menu__item">
                          <Radio
                            label="All"
                            value={_id}
                            checked={
                              selectedCategory === _id && !selectedSubcategory
                            }
                            onChange={() =>
                              handleCategoryChange({
                                category: _id,
                                subcategory: "",
                              })
                            }
                          />
                        </Form.Field>

                        {subcategories.map((subcategory, i) => (
                          <Form.Field className="aside-menu__item" key={i}>
                            <Radio
                              label={subcategory}
                              name={name}
                              value={subcategory}
                              checked={selectedSubcategory === subcategory}
                              onChange={() =>
                                handleCategoryChange({
                                  category: _id,
                                  subcategory,
                                })
                              }
                            />
                            {/* <AmountByPriority
                          {...totals.categories[name][subcategory]}
                        /> */}
                          </Form.Field>
                        ))}
                      </>
                    )}
                  </Form>
                )}
              </div>
            ))}
        </div>
      </div>

      <Modal
        open={isModalOpen}
        showCloseIcon={true}
        closeOnEsc={true}
        onClose={closeModal}
        center
        classNames={{
          modal: "",
        }}
      >
        <CategoryModal
          closeModal={closeModal}
          isEditModeOn={isEditModeOn}
          initFormValues={initFormValues}
          createTicketCategory={createTicketCategory}
          updateTicketCategory={updateTicketCategory}
        />
      </Modal>
    </>
  );
}

export default Sidebar;

const AmountByPriority = (
  { h, m, l, numberOfOpenTickets, priority } // high, medium, low
) => (
  <span>
    {numberOfOpenTickets > 0 ? (
      // <Label circular compact size="mini" color="red">
      //   {h}
      // </Label>
      <span
        style={{
          color: prioritiesColors[priority],
          fontSize: "0.7em",
          paddingRight: "0.4em",
        }}
        className=" bold--text"
      >
        ({numberOfOpenTickets})
      </span>
    ) : null}

    {/* {m > 0 ? (
      // <Label circular compact size="mini" color="yellow">
      //   {m}
      // </Label>
      <span
        style={{
          color: "rgb(173, 173, 13)",
          fontSize: "0.7em",
          paddingRight: "0.4em"
        }}
        className=" bold--text"
      >
        ({m})
      </span>
    ) : null} */}

    {/* {l > 0 ? (
      // <Label circular compact size="mini" color="green">
      //   {l}
      // </Label>
      <span
        style={{ color: "green", fontSize: "0.7em", paddingRight: "0.4em" }}
        className=" bold--text"
      >
        ({l})
      </span>
    ) : null} */}
  </span>
);
