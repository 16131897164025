import React, { useEffect, useState } from "react";
import { Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import { socket } from "../../../containers/Main";

import {
  getTicket,
  updateTicket,
  closeTicket,
  reopenTicket,
  deleteTicket,
  getTicketCategories,
} from "../../../actions/ticket";
import Sidebar from "./Sidebar";
import Main from "./Main";
import CanViewPage from "../../../role-based-access/CanViewPage";

function EditTicket(props) {
  const {
    getTicket,
    updateTicket,
    closeTicket,
    reopenTicket,
    deleteTicket,
    ticket = {},
    isLoading,
    match,
    history,
    currentUser,
    categories,
    getTicketCategories,
  } = props;
  const { description, comments, openBy = {}, status,callcenter } = ticket;

  useEffect(() => {
    getTicket(match.params.id);

    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      // return socket.emit("ticketExit", {
      //   ticket: match.params.id,
      //   user: currentUser.userId,
      // });
    });
  }, [getTicket, match.params.id]);

  useEffect(() => {
    getTicketCategories();
    // socket.emit("userId", currentUser.userId);
  }, [getTicketCategories]);

  useEffect(() => {
    // return () =>
    //   socket.emit("ticketExit", {
    //     ticket: match.params.id,
    //     user: currentUser.userId,
    //   });
  }, []);

  const [canEdit, setCanEdit] = useState(false);
  useEffect(
    () => setCanEdit(openBy._id === currentUser.userId && status !== "Closed"),
    [openBy._id, currentUser.userId, status]
  );

  const handleCloseTicket = () => {
    closeTicket(match.params.id);
  };
  const handleReopenTicket = () => {
    reopenTicket(match.params.id);
  };

  const handleDeleteTicket = () => {
    deleteTicket({ id: match.params.id, history });
  };

  // if (isLoading)
  //   return (
  //     <Dimmer active inverted>
  //       <Loader inverted size="medium">
  //         Loading
  //       </Loader>
  //     </Dimmer>
  //   );

  return (
    <CanViewPage path="/tickets/:id">
      <main className="page-wrapper a-wrapper">
        <Link to="/tickets">
          <Icon name="arrow left" />
          Tickets List
        </Link>
        <div className="ticket-grid">
          <Sidebar
            {...ticket}
            isLoading={isLoading}
            updateTicket={updateTicket}
            ticketId={ticket._id}
            canEdit={canEdit}
            categories={categories}
          />
          <Main
            description={description}
            callcenter={callcenter}
            comments={comments}
            ticketId={ticket._id}
            // currentUser={currentUser.userId}
            isLoading={isLoading}
            updateTicket={updateTicket}
            canEdit={false}
            status={status}
          />
          <div className="close-ticket-btn">
            {(openBy._id === currentUser.userId||currentUser.roles.includes('super_admin') )&& status === "Open" && (
              <Button
                size="large"
                icon="check square outline"
                labelPosition="left"
                color="yellow"
                content="Close ticket"
                onClick={handleCloseTicket}
              />
            )}
            {(openBy._id === currentUser.userId||currentUser.roles.includes('super_admin')) && status === "Closed" && (
              <Button
                size="large"
                icon="check square outline"
                labelPosition="left"
                content="Reopen ticket"
                onClick={handleReopenTicket}
              />
            )}
            {(openBy._id === currentUser.userId ||currentUser.roles.includes('super_admin'))&& (
              <Button
                size="large"
                icon="trash alternate"
                labelPosition="left"
                content="Delete ticket"
                onClick={handleDeleteTicket}
              />
            )}
          </div>
        </div>
      </main>
    </CanViewPage>
  );
}

const mapStateToProps = (reduxState) => ({
  ticket: reduxState.tickets.openTicket,
  categories: reduxState.tickets.categories,
  currentUser: reduxState.user.currentUser,
  isLoading: reduxState.tickets.isLoading,
});

export default connect(mapStateToProps, {
  getTicket,
  updateTicket,
  closeTicket,
  reopenTicket,
  deleteTicket,
  getTicketCategories,
})(EditTicket);
