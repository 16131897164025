import React, { useReducer, useEffect } from "react";
import {
  Segment,
  Icon,
  Header,
  Divider,
  TextArea,
  Dropdown,
  Radio,
  Form,
  Button,
  Checkbox
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { createTicket, getTicketCategories } from "../../../actions/ticket";
import UserFilter from "../../Filters/UserFilter";
import Participants from "./Participants";
import { useState } from "react";
import CanViewPage from "../../../role-based-access/CanViewPage";

// export const categories = ["IT", "Orders", "Product"];

const requiredFields = ["category", "priority", "description"];

const initialState = {
  values: {
    // subject: "",
    category: "",
    subcategory: "",
    participants: [],
    assignedTo: "",
    priority: "Medium"
  },
  isColdCall:true,
  isTouched: [],
  isSubmitted: false,
  successfulSubmit: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "IS_COLD_CALL":
      return {
        ...state,
        isColdCall:action.payload
      }
    case "CHANGE_FIELD":
      const { name, value } = action.payload;
      let newIsTouched = state.isTouched;
      if (requiredFields.includes(name) && !newIsTouched.includes(name)) {
        newIsTouched.push(name);
      }
      if (name === "participants")
        return {
          ...state,
          values: {
            ...state.values,
            participants: [...state.values.participants, value]
          },
          isTouched: newIsTouched
        };
      return {
        ...state,
        values: { ...state.values, [name]: value },
        isTouched: newIsTouched
      };

    case "DELETE_PARTICIPANT":
      return {
        ...state,
        values: {
          ...state.values,
          participants: state.values.participants.filter(
            p => p !== action.payload
          )
        }
      };

    case "SUBMIT_FORM":
      let { values } = state;
      const allRequiredFieldsCompleted = !requiredFields.find(
        key => !values[key]
      );

      if (allRequiredFieldsCompleted||state.isColdCall)
        return {
          ...state,
          isSubmitted: true,
          successfulSubmit: true,
          isTouched: []
        };

      return { ...state, isSubmitted: true, successfulSubmit: false };

    case "CLEAR_FORM":
      return { ...state, ...initialState, isTouched: [] };

    default:
      return state;
  }
};

function CreateTicket({
  createTicket,
  history,
  categories,
  getTicketCategories
}) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { values, isTouched, isSubmitted, successfulSubmit,isColdCall } = state;
  const {
    // subject,
    category,
    subcategory,
    participants,
    priority,
    assignedTo,
    description
  } = values;

  // get categories and subcategories dropdown options
  useEffect(() => getTicketCategories(), [getTicketCategories]);
  
  const [subcategoriesOption, setSubcategoriesOption] = useState([]);
  // const [isColdCall,setIsColdCall]=useState(true)

  useEffect(() => {
    if (category) {
      const options = categories
        .find(c => c._id === category)
        .subcategories.map((subcategory, i) => ({
          key: i,
          value: subcategory,
          text: subcategory
        }));
      setSubcategoriesOption(options);
    }
  }, [category, categories]);

  ////////
  const handleFieldChange = (e, { name, value }) => {
    dispatch({ type: "CHANGE_FIELD", payload: { name, value } });
  };

  const deleteParticipant = id =>
    dispatch({ type: "DELETE_PARTICIPANT", payload: id });

  const handleCancel = e => {
    e.preventDefault();
    dispatch({ type: "CLEAR_FORM" });
    // history.push("/tickets/");
  };

  const handleCreate = e => {
    e.preventDefault();
    dispatch({ type: "SUBMIT_FORM" });
  };

  useEffect(() => {
    if (successfulSubmit) {
      createTicket(values, history);
    }
  }, [successfulSubmit, values, history, createTicket]);

  return (
    <CanViewPage path="/tickets/create/new">
      <main className="page-wrapper a-wrapper">
        <Link to="/tickets">
          <Icon name="arrow left" />
          Tickets List
        </Link>
        <Segment padded>
          <Header as="h4">New Ticket</Header>
            <div >
               <Checkbox
                    label="Call Center - cold calls"
                    name="isColdCall"
                    checked={isColdCall}
                    onChange={()=>     dispatch({ type: "IS_COLD_CALL", payload: !isColdCall })}
                  />
                 {isColdCall&& <div>
                    <label className="new-ticket-form__label">Assign to User:</label>
                    <UserFilter
                      name="assignedTo"
                      value={assignedTo}
                      handleChange={handleFieldChange}
                    />

                    <div>

                       <label className="new-ticket-form__label">
                      Category:{" "}
                          <span className="new-ticket-form__required-symbol">*</span>
                        </label>
                        <Dropdown
                          placeholder="category..."
                          name="category"
                          value={category}
                          clearable
                          selection
                          search
                          selectOnBlur={false}
                          closeOnChange={true}
                          onChange={handleFieldChange}
                          options={categories.map(({ name, _id }) => ({
                            key: _id,
                            value: _id,
                            text: name
                          }))}
                          error={
                            (isTouched.includes("category") || isSubmitted) &&
                            !values.category
                          }
                        />
                        {(isTouched.includes("category") || isSubmitted) &&
                          !values.category && (
                            <span className="user-form__error-msg">
                              ! The category is required
                            </span>
                          )}
                
                    </div>
                    <label className="new-ticket-form__label">Subcategory:</label>
                      <Dropdown
                        placeholder="subcategory..."
                        disabled={!category}
                        name="subcategory"
                        value={subcategory}
                        clearable
                        selection
                        search
                        selectOnBlur={false}
                        closeOnChange={true}
                        onChange={handleFieldChange}
                        options={subcategoriesOption}
                        error={
                          (isTouched.includes("category") || isSubmitted) &&
                          !values.category
                        }
                      />
              
                </div>
                
                }
                
            </div>

                 

          <form
            autoComplete="off"
            onSubmit={handleCreate}
            // className="ticket-grid"
          >
      {!isColdCall&&    
          <div className="new-ticket-description">
              <Header as="h4">
                Description:{" "}
                <span className="new-ticket-form__required-symbol">*</span>
              </Header>
              {(isTouched.includes("description") || isSubmitted) &&
                !values.description && (
                  <span className="user-form__error-msg">
                    ! The description is required
                  </span>
                )}
              <Form>
                <TextArea
                  padding="Ticket description ..."
                  name="description"
                  value={description}
                  onChange={handleFieldChange}
                />
              </Form>
            </div>}
            
            {isColdCall &&<div>
                <Divider horizontal>Client</Divider>
                 <Form>
                        <Form.Group widths='equal'>
                          <Form.Input
                            fluid
                            id='form-subcomponent-shorthand-input-first-name'
                            label='Name'
                            placeholder='First Last names'
                            name='callcenter.client.name'
                            onChange={handleFieldChange}
                          />
                          <Form.Input
                            fluid
                            id='form-subcomponent-shorthand-input-last-name'
                            label='Phone'
                            placeholder='608434343'
                            name='callcenter.client.phone'
                            onChange={handleFieldChange}
                          />
                          <Form.Input
                            fluid
                            id='form-subcomponent-shorthand-input-last-name'
                            label='Position'
                            placeholder='sales manager ...'
                            name='callcenter.client.position'
                            onChange={handleFieldChange}

                          />
                         
                        </Form.Group>
                         <Form.Group>
                            <Form.Input
                                  width={6}
                                  fluid
                                  id='form-subcomponent-shorthand-input-last-name'
                                  label='email'
                                  placeholder='name@email.com'
                                  name='callcenter.client.email'
                                  onChange={handleFieldChange}
                          />
                            <Form.Input
                                  width={6}
                                  fluid
                                  id='form-subcomponent-shorthand-input-last-name'
                                  label='e-shop'
                                  placeholder='http://eshop'
                                  name='callcenter.client.web'
                                  onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Form>
              </div>}
                            <Divider></Divider>

            {!isColdCall&&<div className="new-ticket-form">

              <div className="new-ticket-form__grid">
                {/* <label className="new-ticket-form__label">
                Subject:{" "}
                <span className="new-ticket-form__required-symbol">*</span>
              </label>
              <Input
                type="text"
                name="subject"
                value={subject}
                onChange={handleFieldChange}
                error={
                  (isTouched.includes("subject") || isSubmitted) &&
                  !values.subject
                }
              />
              {(isTouched.includes("subject") || isSubmitted) &&
                !values.subject && (
                  <span className="user-form__error-msg">
                    ! The subject is required
                  </span>
                )} */}

                <label className="new-ticket-form__label">
                  Category:{" "}
                  <span className="new-ticket-form__required-symbol">*</span>
                </label>
                <Dropdown
                  placeholder="category..."
                  name="category"
                  value={category}
                  clearable
                  selection
                  search
                  selectOnBlur={false}
                  closeOnChange={true}
                  onChange={handleFieldChange}
                  options={categories.map(({ name, _id }) => ({
                    key: _id,
                    value: _id,
                    text: name
                  }))}
                  error={
                    (isTouched.includes("category") || isSubmitted) &&
                    !values.category
                  }
                />
                {(isTouched.includes("category") || isSubmitted) &&
                  !values.category && (
                    <span className="user-form__error-msg">
                      ! The category is required
                    </span>
                  )}

                <label className="new-ticket-form__label">Subctegory:</label>
                <Dropdown
                  placeholder="subcategory..."
                  disabled={!category}
                  name="subcategory"
                  value={subcategory}
                  clearable
                  selection
                  search
                  selectOnBlur={false}
                  closeOnChange={true}
                  onChange={handleFieldChange}
                  options={subcategoriesOption}
                />
              </div>

              <div className="new-ticket-form__grid">
                <label className="new-ticket-form__label">Participants:</label>
                <Participants
                  value={participants}
                  handleChange={handleFieldChange}
                  deleteParticipant={deleteParticipant}
                />
                <label className="new-ticket-form__label">Assign to:</label>
                <UserFilter
                  name="assignedTo"
                  value={assignedTo}
                  handleChange={handleFieldChange}
                />
              </div>

              <div className="new-ticket-form__grid">
                <label className="new-ticket-form__label">
                  Priority:{" "}
                  <span className="new-ticket-form__required-symbol">*</span>
                </label>
                <div className="new-ticket-form__priority">
                  <Radio
                    label="High"
                    name="priority"
                    value="High"
                    checked={priority === "High"}
                    onChange={handleFieldChange}
                  />
                  <Radio
                    label="Medium"
                    name="priority"
                    value="Medium"
                    checked={priority === "Medium"}
                    onChange={handleFieldChange}
                  />
                  <Radio
                    label="Low"
                    name="priority"
                    value="Low"
                    checked={priority === "Low"}
                    onChange={handleFieldChange}
                  />
                  {(isTouched.includes("priority") || isSubmitted) &&
                    !values.priority && (
                      <span className="user-form__error-msg">
                        ! The priority is required
                      </span>
                    )}
                </div>
              </div>
            </div>
}
            <div className="mt--2">
              <Button onClick={handleCancel}>Cancel</Button>

              <Button type="submit" color="blue" onClick={handleCreate}>
                Submit ticket
              </Button>
            </div>
          </form>
        </Segment>
      </main>
    </CanViewPage>
  );
}

const mapStateToProps = reduxState => ({
  categories: reduxState.tickets.categories
});
export default connect(mapStateToProps, { createTicket, getTicketCategories })(
  CreateTicket
);
