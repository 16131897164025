import React, { useState } from "react";
import {
  Segment,
  Header,
  Button,
  Dimmer,
  Loader,
  Form,
  
} from "semantic-ui-react";

import Comments from "./Comments";

function Main(props) {
  const {
    description,
    comments,
    ticketId,
    updateTicket,
    callcenter,
    isLoading,
    canEdit,
    status
  } = props;

  const [isEditOn, setEditOn] = useState(true);
  const [update, setUpdate] = useState("");

  const handleEditOn = initialValue => {
    setEditOn(true);
    setUpdate(initialValue);
  };
  const handleFieldChange = (e, { value }) => setUpdate(value);
  const handleUpdatesSave = () => {
    updateTicket({ id: ticketId, query: { description: update } });
    setEditOn(false);
    setUpdate("");
  };
  const handleEditOff = () => {
    setEditOn(false);
    setUpdate("");
  };

  if (isLoading)
    return (
      <div
        style={{ position: "relative", height: "70vh" }}
        className="ticket-list"
      >
        <Dimmer active inverted>
          <Loader inverted size="medium">
            Loading
          </Loader>
        </Dimmer>
      </div>
    );

  const ticketDescription = <Segment
        padded
        // color="yellow"
        // inverted
        style={{
          backgroundColor: "rgb(255, 255, 168)",
          boxShadow: "1px 1px 2px 2px rgba(51, 51, 51, 0)",
          border: "none"
        }}
      >
     <div className="flex--left mb--1">
          <Header as="h3">Ticket description:</Header>
          {canEdit && !isEditOn && (
            <Button
              style={{
                boxShadow: "none",
                marginTop: "-1.2em",
                padding: "1em 1.3em",
                display: "block"
              }}
              basic
              compact
              size="mini"
              icon="pencil"
              onClick={() => handleEditOn(description)}
            />
          )}
        </div>
        {isEditOn ? (
          <Form style={{ paddingTop: "1em" }}>
            <Form.TextArea
              placeholder="Description ..."
              value={update}
              onChange={handleFieldChange}
            />
            <div className="flex--right">
              <Button.Group compact>
                <Button icon="times" onClick={handleEditOff} />
                <Button icon="pencil" primary onClick={handleUpdatesSave} />
              </Button.Group>
            </div>
          </Form>
        ) : (
          <p className="large--text">{description}</p>
        )}
      </Segment>
      
  const callcenterClientInfo =<Segment>
                                {callcenter&& <h4>
                                 <a href={`http://${callcenter.client.web}`} target='_blank' rel="noreferrer">{callcenter.client.web}</a></h4>}
                                 <tr>
                                   <th>Name:</th>
                                   <th>{callcenter &&callcenter.client.name}</th>
                                 </tr>
                                 <tr>
                                 <th>Phone:</th>
                                   <th>{callcenter &&callcenter.client.phone}</th>
                                 </tr>
                                 <tr>
                                 <th>Email:</th>
                                   <th>{callcenter &&callcenter.client.email}</th>
                                 </tr>
                                 <tr>
                                 <th>Pozition:</th>
                                   <th>{callcenter &&callcenter.client.position}</th>
                                 </tr>
                              
                                 {/* <p>Country:{callcenter &&callcenter.client.country}</p> */}
                                 
                            </Segment>
  return (
    <div>
      {ticketDescription }
      {callcenter&&callcenter.client && callcenterClientInfo}
      <Segment padded>
        <Header as="h3">Comments</Header>

        <Comments comments={comments} ticketId={ticketId} status={status} />
      </Segment>
    </div>
  );
}

export default Main;
