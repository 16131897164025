import React, { useState, useEffect } from "react";
import { Dropdown, Label, Icon } from "semantic-ui-react";
import { connect } from "react-redux";

import { getUsers } from "../../../actions/user";
import Avatar from "../../../avatar.png";

function Participants({
  value = [],
  handleChange,
  deleteParticipant,

  fluid = false,
  error = false,
  style = {},

  users = [],
  getUsers,
}) {
  const [options, setOptions] = useState([]);

  useEffect(() => getUsers(), [getUsers]);
  useEffect(
    () =>
      setOptions(
        users
          .filter(({ _id }) => !value.includes(_id))
          .map(({ text, _id }) => ({
            key: _id,
            value: _id,
            text,
          }))
      ),
    [users, value]
  );

  const [participants, setParticipants] = useState([]);
  useEffect(() => {
    if (users.length) {
      const p = value.map((participant) => {
        const foundUser = users.find((user) => user._id === participant);
        const { value, _id } = foundUser;
        return { value, _id };
      });
      setParticipants(p);
    }
  }, [value, users]);

  return (
    <div>
      {value.length > 0 && (
        <p>
          {participants.map(({ value, _id }) => (
            <Label
              key={_id}
              image
              size="tiny"
              style={{ marginBottom: "0.3em" }}
            >
              <img src={Avatar} alt=''/>
              {value}
              <Icon name="delete" onClick={() => deleteParticipant(_id)} />
            </Label>
          ))}
        </p>
      )}

      <Dropdown
        placeholder="users..."
        name="participants"
        // value={value[value.length - 1]}
        fluid
        error={error}
        style={style}
        // clearable
        selection
        search
        selectOnBlur={false}
        closeOnChange={true}
        onChange={handleChange}
        options={options}
      />
    </div>
  );
}

const mapStateToProps = (reduxState) => ({
  users: reduxState.users.userlist,
});

export default connect(mapStateToProps, { getUsers })(Participants);
