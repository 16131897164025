import React from "react";
import { Segment, Button, Input } from "semantic-ui-react";
// import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { clearFilters } from "../../../actions/filtersmain";
import { DatePicker } from "rsuite";
import ProductFilterList from "../../Filters/ProductFilterList";
import BusinessClientFilter from "../../Filters/BusinessclientFilter";
import CourierFilter from "../../Filters/CourierFilter";
import UserFilter from "../../Filters/UserFilter";
// import ChangelogsFilter from '../../Filters/ChangelogsFilters'
import CountryFilter from "../../Filters/CountryFilter";
import WarehouseFilter from "../../Filters/WarehouseFilter";
import CanView from "../../../role-based-access/CanView.jsx";

import { useUpdateContext } from "../../../context/UpdateContext";

const itemStyle = {
  margin: "2px",
  minWidth: "330px",
  maxWidth: "360px",
  border: "none !important",
  color: "#3F72AF !important",
};
function Filters({ isLoading = false, setSearchButton, searchButton }) {
  const {
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    resetFilters,
  } = useUpdateContext();

  return (
    <Segment style={{ marginTop: "15px" }}>
      <div className="flex--left">
        <BusinessClientFilter
          useFilter={true}
          style={itemStyle}
          name="businessclient"
          disabled={isLoading}
          fluid={true}
        />

        <ProductFilterList disabled={isLoading} fluid={true} multiple={true} />

        {/* Courier: */}
        <CourierFilter style={itemStyle} disabled={isLoading} fluid={true} />

        <CanView path="/users" action="read">
          <UserFilter
            disabled={isLoading}
            multiple={true}
            selectOnBlur={false}
            clearable
            selection
            closeOnChange={true}
            name="assigned"
            fluid={true}
          />
        </CanView>
        <CountryFilter disabled={isLoading} fluid={true} style={itemStyle} />

        <WarehouseFilter style={itemStyle} disabled={isLoading} fluid={true} />

        {/* <ChangelogsFilter
            handleChange={handleUpdateFilter}
            value={changeLogs}
          >
          </ChangelogsFilter> */}

        <div className="flex--left">
          <DatePicker
            disabled={isLoading}
            oneTap
            value={dateFrom}
            isClearable={true}
            // placement="leftStart"
            format="DD-MM-YYYY"
            onChange={(date) => handleDateFromChange(date)}
            placeholder="from..."
            style={itemStyle}
          ></DatePicker>

          <DatePicker
            disabled={isLoading}
            oneTap
            value={dateTo}
            isClearable={true}
            // placement="leftStart"
            format="DD-MM-YYYY"
            onChange={(date) => handleDateToChange(date)}
            placeholder="to..."
            style={itemStyle}
          ></DatePicker>
        </div>
      </div>
      <div className="flex--between">
        <Button
          fluid={true}
          size="sm"
          onClick={() => {
            resetFilters();
          }}
          style={{ maxWidth: "330px" }}

        >
          clear filters
        </Button>
        <Button
          color="blue"
          fluid={true}
          style={{ maxWidth: "330px" }}
          onClick={(e, data) => setSearchButton(!searchButton)}
        >
          {" "}
          Search
        </Button>
      </div>
    </Segment>
  );
}

export default connect(null, { clearFilters })(Filters);
