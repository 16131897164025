import React from "react";

const tabs = [
  {
    value: "all",
    text: "All",
  },
  {
    value: "assignedTo",
    text: "Assigned to me",
  },
  {
    value: "openBy",
    text: "Open by me",
  },
  {
    value: "participants",
    text: "Participating in",
  },
];

function Tabs({ handleTabchange, activeTab, totals }) {
  return (
    <ul className="status-nav">
      {tabs.map(({ value, text }, i) => (
        <li
          key={i}
          className={`status-nav__item   ${
            activeTab === value ? "status-nav__item_active" : null
          }`}
          onClick={() => handleTabchange(value)}
        >
          {text}
          {totals[value] > 0 && (
            <span className="span-text_tabs">({totals[value]})</span>
          )}
        </li>
      ))}
    </ul>
  );
}

export default Tabs;
