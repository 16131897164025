import React, { useState, useEffect } from "react";
import {
  Header,
  Divider,
  Input,
  Button,
  Segment,
  Icon,
  Loader,
  Label,
  Checkbox,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import { getUser, updateUser } from "../../../actions/user";
import CanViewPage from "../../../role-based-access/CanViewPage";
import RolesFilter from "../../Filters/RolesFilter";
import WarehouseFilter from "../../Filters/WarehouseFilter";
import BusinessclientFilter from "../../Filters/BusinessclientFilter";

function UserForm(props) {
  const { match } = props;
  const [user, setUser] = useState();
  const [isLoading, setLoading] = useState(false);
  const [password, setNewPass] = useState(false);
  const [updateField, setShowUpdateField] = useState({
    name: null,
    value: undefined,
  });

  useEffect(() => {
    setLoading(true)
    getUser(match.params.id).then((user) => {
      setLoading(false)
      setUser(user)})}, [match.params.id]);

  const handleUpdateField = (segment) => {
    updateUser({
      query: { [updateField.name]: updateField.value },
      id: user._id,
    }).then((user) => {
      setUser(user);
    });

    setShowUpdateField({ name: null, value: null });
  };

  if(!user) return (<div>Loading...</div>)
  return (
    <CanViewPage path="/users/:id">
      <main className="page-wrapper a-wrapper">
        <Link to="/users">
          <Icon name="arrow left" />
          Users List
        </Link>
        <div style={{marginTop:"25px"}}>
                <Header as="h3">
                  Edit User
                  {user.businessclient && (
                    <Label color="orange"> {user.businessclient.name} - <a
                    href={`http://client.wyzbox.com/internal/loginas/${user.username}/${user.password}`}
                    target="_blank"
                  >
                    logIn as
                  </a></Label>
                  )}
                </Header>
              </div>

        <Segment padded style={{maxWidth:'500px'}}>
          {isLoading ? (
            <div style={{ marginTop: "2em", marginBottom: "2em" }}>
              <Loader active inline="centered" />
            </div>
          ) : (
            <section >
              <table>
                <tr id="username" style={{ height: "33px" }}>
                  <td style={{ width: "100px", fontWeight: "bold" }}>
                    First name
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {updateField.name === "username" && (
                      <div>
                        <Input
                          value={updateField.value}
                          style={{ height: "25px" }}
                          onChange={(e, { value }) => {
                            setShowUpdateField({ ...updateField, value });
                          }}
                        ></Input>
                      </div>
                    )}
                    {updateField.name !== "username" && user.username}
                  </td>
                  <td style={{ textAlign: "right", width: "150px" }}>
                    {updateField.name === "username" && (
                      <div>
                        <Button
                          size="mini"
                          basic
                          color="red"
                          onClick={() => {
                            setShowUpdateField({ name: null });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="mini"
                          basic
                          color="green"
                          onClick={() => handleUpdateField("username")}
                        >
                          Save
                        </Button>
                      </div>
                    )}

                    {updateField.name !== "username" && (
                      <Button
                        icon="edit"
                        basic
                        size="mini"
                        onClick={() => {
                          setShowUpdateField({
                            name: "username",
                            value: user.username,
                          });
                        }}
                      ></Button>
                    )}
                  </td>
                </tr>

                <tr id="password" style={{ height: "33px" }}>
                  <td style={{ width: "100px", fontWeight: "bold" }}>
                    Password
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {updateField.name === "password" && (
                      <div>
                        <Input
                          type="password"
                          value={password}
                          style={{ height: "25px" }}
                          onChange={(e, { value }) => {
                            setShowUpdateField({ ...updateField, value })
                            setNewPass(value)
                          }}
                        ></Input>
                      </div>
                    )}
                    *****...
                  </td>
                  <td style={{ textAlign: "right", width: "150px" }}>
                    {updateField.name === "password" && (
                      <div>
                        <Button
                          size="mini"
                          basic
                          color="red"
                          onClick={() => {
                            setShowUpdateField({ name: null });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="mini"
                          basic
                          color="green"
                          onClick={() => handleUpdateField("password")}
                        >
                          Save
                        </Button>
                      </div>
                    )}

                    {updateField.name !== "password" && (
                      <Button
                        icon="edit"
                        basic
                        size="mini"
                        onClick={() => {
                          setShowUpdateField({
                            name: "password",
                            value: user.password,
                          });
                        }}
                      ></Button>
                    )}
                  </td>
                </tr>

                <tr id="roles" style={{ height: "33px" }}>
                  <td style={{ width: "100px", fontWeight: "bold" }}>Roles</td>
                  <td style={{ textAlign: "left" }}>
                    {updateField.name === "roles" && (
                      <RolesFilter
                        multiple={true}
                        value={user.roles}
                        handleChange={(e, { name, value }) =>
                          setShowUpdateField({ name, value })
                        }
                        // error={showError({ field: "roles", isArray: true })}
                      />
                    )}
                    {updateField.name !== "roles" &&
                      user.roles.map((role) => `${role}`)}
                  </td>
                  <td style={{ textAlign: "right", width: "150px" }}>
                    {updateField.name === "roles" && (
                      <div>
                        <Button
                          size="mini"
                          basic
                          color="red"
                          onClick={() => {
                            setShowUpdateField({ name: null });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="mini"
                          basic
                          color="green"
                          onClick={() => handleUpdateField("roles")}
                        >
                          Save
                        </Button>
                      </div>
                    )}

                    {updateField.name !== "roles" && (
                      <Button
                        icon="edit"
                        basic
                        size="mini"
                        onClick={() => {
                          setShowUpdateField({
                            name: "roles",
                            value: user.roles,
                          });
                        }}
                      ></Button>
                    )}
                  </td>
                </tr>

                <tr id="location" style={{ height: "33px" }}>
                  <td style={{ width: "100px", fontWeight: "bold" }}>
                    Warehouse
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {updateField.name === "location" && (
                      <WarehouseFilter
                       useFilter={false}
                        multiple={false}
                        value={updateField.value}
                        handleChange={value => {
                          setShowUpdateField({
                            name: "location",
                            value,
                          });
                        }}
                      />
                    )}
                    {updateField.name !== "location" &&
                      user.location &&
                      user.location.name}
                  </td>
                  <td style={{ textAlign: "right", width: "150px" }}>
                    {updateField.name === "location" && (
                      <div>
                        <Button
                          size="mini"
                          basic
                          color="red"
                          onClick={() => {
                            setShowUpdateField({ name: null });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="mini"
                          basic
                          color="green"
                          onClick={() => handleUpdateField("location")}
                        >
                          Save
                        </Button>
                      </div>
                    )}

                    {updateField.name !== "location" && (
                      <Button
                        icon="edit"
                        basic
                        size="mini"
                        onClick={() => {
                          setShowUpdateField({
                            name: "location",
                            value: user.location,
                          });
                        }}
                      ></Button>
                    )}
                  </td>
                </tr>
              </table>

              <Divider></Divider>

              <div className="flex--between">
                <Checkbox
                  label="Is businessclient"
                  name="businessclient"
                  checked={!!user.businessclient|| updateField.name ==='businessclient'}
                  onChange={(e, { checked }) => {
                    if (checked)
                      setShowUpdateField({
                        name: "businessclient",
                      });
                    if (!checked) {
                      updateUser({
                        query: {businessclient:null },
                        id: user._id,
                      }).then((user) => {
                        setUser(user)
                        setShowUpdateField({
                          name: null,
                        });
                      })
                    }
                      
                  }}
                />

                <div className="flex--left">
                  {
                    updateField.name !== "businessclient" && (
                      <div style={{ marginRight: "10px" }}>
                        {user.businessclient&&user.businessclient.name}
                      </div>
                    )}

                  {updateField.name !== "businessclient" && (
                    <Button
                      icon="edit"
                      basic
                      size="mini"
                      onClick={() => {
                        setShowUpdateField({
                          name: "businessclient",
                        });
                      }}
                    ></Button>
                  )}
                </div>

                {updateField.name === "businessclient" && (
                  <div className="flex--left">
                    <BusinessclientFilter
                     useFilter={false}
                      // value={user.businessclient}
                      handleChange={(value) => {
                        setShowUpdateField({
                          name:"businessclient",
                          value,
                        });
                      }}
                    />

                    <div style={{marginLeft:'10px'}}>
                      <Button
                        size="mini"
                        basic
                        color="red"
                        onClick={() => {
                          setShowUpdateField({ name: null });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="mini"
                        basic
                        color="green"
                        onClick={() => handleUpdateField("location")}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <Checkbox
                  label="Is Active"
                  name="status.isActive"
                  checked={user.status.isActive}
                  onChange={(e, { checked }) => {
                
                
                      updateUser({
                        query: { "status.isActive": checked },
                        id: user._id,
                      }).then((user) => {
                        setUser(user)
                        setShowUpdateField({
                          name: null,
                        });
                      })
                    }}
                />

            </section>
          )}
        </Segment>
      </main>
    </CanViewPage>
  );
}

export default UserForm;
