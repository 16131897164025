import React, { useState, useEffect } from "react";
import { Input, Button, Dropdown,Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {Nav} from 'rsuite'
import {
  updateFilters,
  getTickets,
  getTotals,
  getTicketCategories,
  createTicketCategory,
  updateTicketCategory,
  setSearchState,
  updateMany
} from "../../../actions/ticket";
import ItemsPerPage from "../../ItemsPerPage";
import Pagination from "../../Filters/Pagination";
import Tabs from "./Tabs";
import Sidebar from "./Sidebar";
import Table from "./Table";
import Sort from "./Sort";
import DateFilter from "../../Filters/DateFilter";
import CanViewPage from "../../../role-based-access/CanViewPage";
import CanView from "../../../role-based-access/CanView";
import UserFilter from "../../Filters/UserFilter";
import "../tickets.scss";

const statuses = ["All", "Open", "Closed"];
const ccstatuses=["All","New","Email","In progress","Offer sent: no respense","Offer sent: rejected","Call later","Not interested","Contract:sent","Contract: signed"]

// const initFilters = {
//   activeTab: "all",
//   status: "Open",
//   priority: "All",
//   category: "",
//   subcategory: "",
//   searchTerm: "",
//   sortBy: "createdAtUp", // priorityUp/Down , updatedAtUp/Down
//   currentPage: 1,
//   itemsPerPage: 10,
//   dateFrom: null,
//   dateTo: null,
// };

const styles = {
  marginBottom: 50
};

const CustomNav = ({ active, onSelect,totals, ...props }) => {
  return (
    <Nav {...props} activeKey={active} onSelect={onSelect} style={styles}>
      {ccstatuses.map((status)=>(
      <Nav.Item eventKey={status}
          >{`${status} (${totals.ccstatuses?totals.ccstatuses[status]:'0'})`}</Nav.Item>))}
    </Nav>
  );
};


function ListTicket(props) {
  const {
    total,
    totals,
    isLoading,
    tickets,
    filters,
    updateFilters,
    getTickets,
    getTotals,
    currentUser,
    getTicketCategories,
    createTicketCategory,
    updateTicketCategory,
    categories,
    setSearchState,
    searchState,
    updateMany
    } = props;

  const [canEdit, setCanEdit] = useState(false);
  const [ccstatus, setCCstatus] = useState('New');

  useEffect(
    () => currentUser.roles && setCanEdit(currentUser.roles.includes("admin")),
    [currentUser]
  );

  const {
    status,
    priority,
    activeTab,
    category,
    subcategory,
    sortBy,
    assignedTo,
    itemsPerPage,
    currentPage,
    dateFrom,
    dateTo,
    
  } = filters;

  const [users,setUsers] = useState([])
  useEffect(() => getTickets({...filters,ccstatus}), [filters]);
  useEffect(() => getTotals({...filters,ccstatus}), [filters.status, filters.activeTab,ccstatus,assignedTo]);
  useEffect(()=>{
    setUsers(localStorage.userList)

  },[])


  const handleTabchange = (activeTab)         => updateFilters({ activeTab, currentPage: 1 });
  const handleDateToChange = (date)           => updateFilters({ dateTo: date, currentPage: 1 });
  const handleDateFromChange = (date)         => updateFilters({ dateFrom: date, currentPage: 1 });
  const handleStatusChange = (e, { value })   => updateFilters({ status: value, currentPage: 1 });
  const handleAssignedChange = (e,{value}) => updateFilters({ assignedTo: value, currentPage: 1, activeTab: "all" });
  const handlePriorityChange = (e, { value }) => updateFilters({ priority: value, currentPage: 1 });
  const handleCategoryChange = ({ category, subcategory }) => 
                                                 updateFilters({ category, subcategory, currentPage: 1 });
  


  // const [searchState, setSearchState] = useState("");
  const [requestSent, setRequestSent] = useState(false);

  const handleSearch = (e, { value }) => {
    // if (isLoading) return;
    if (value.length === 0 ) {
      setSearchState(value);
      updateFilters({ searchTerm: null, currentPage: 1 });

      setCCstatus("New");
      handleTabchange("assignedTo")
      return;
    }
    setSearchState(value);
    handleTabchange("all")
    setCCstatus("All")
    
    if (!requestSent) {
      updateFilters({ searchTerm: value, currentPage: 1 });
      setRequestSent(true);
      setTimeout(() => setRequestSent(false), 600);
    }
  };

  const handleItemsPerPageChange = (e, { value }) => {
    updateFilters({ itemsPerPage: value, currentPage: 1 });
  };
  const handleCurrentPage = ({ currentPage }) => updateFilters({ currentPage });

  return (
    <CanViewPage path="/tickets">
      <main className="page-wrapper tickets-page-wrapper">
        <div className="flex--between ticket-tabs">
          <Tabs
            handleTabchange={handleTabchange}
            activeTab={activeTab}
            totals={totals.tabs}
          />
          <Link to="/tickets/create/new">
            <Button
              // basic
              className="add-ticket-btn"
              // boxShadow: "none"
              content="Add ticket"
              // icon="plus"
            />
          </Link>
        </div>
        
               <CustomNav appearance="subtle" active={ccstatus} 
                          totals={totals}
                          onSelect={(ccstatus)=>{
                                                  updateFilters({ 'ccstatus': ccstatus, currentPage: 1 })
                                                  setCCstatus(ccstatus)} }/>

        <section className="ticket-grid">
        
          <div>
          <Input
                  style={{ marginBottom: "1em",width:"100%" }}
                  loading={isLoading}
                  placeholder="search ..."
                  icon="search"
                  value={searchState}
                  onChange={handleSearch}
                />
            <DateFilter
              dateFrom={dateFrom}
              dateTo={dateTo}
              handleDateFromChange={handleDateFromChange}
              handleDateToChange={handleDateToChange}
              style={{ marginBottom: "0.6em" }}
            />
            <Sidebar
              totals={totals}
              priority={priority}
              handlePriorityChange={handlePriorityChange}
              handleCategoryChange={handleCategoryChange}
              category={category}
              subcategory={subcategory}
              canEdit={canEdit}
              getTicketCategories={getTicketCategories}
              createTicketCategory={createTicketCategory}
              updateTicketCategory={updateTicketCategory}
              categories={categories}
            />
          </div>

          <div>
            
            <div className="ticket-filters">
              
              <div className="flex--between vertical--center">
                {/* <Input
                  style={{ marginRight: "1em" }}
                  loading={isLoading}
                  placeholder="description ..."
                  icon="search"
                  value={searchState}
                  onChange={handleSearch}
                /> */}
                <div className="ml--2 flex--left">
                  <div style={{marginRight:'20px'}}>
                    <span className="mr--1">Status:</span>
                    <Dropdown
                      placeholder="status..."
                      name="status"
                      value={status}
                      search
                      // selectOnBlur={false}
                      closeOnChange={true}
                      onChange={handleStatusChange}
                      options={statuses.map((s) => ({
                        key: s,
                        value: s,
                        text: s,
                      }))}
                    />
                  </div>

                  <CanView path="/tickets" action="assigned-user">
                    <div>
                      <span className="mr--1">Assigned to:</span>
                      <UserFilter
                        placeholder="user..."
                        selection={false}
                        name="assignedTo"
                        value={assignedTo}
                        handleChange={handleAssignedChange}
                      />
                    </div>
                  </CanView>
                </div>
              </div>

              <div className="flex--between vertical--center">
                <Sort updateFilters={updateFilters} sortBy={sortBy} />
                <ItemsPerPage
                  style={{ marginLeft: "2em" }}
                  selection={false}
                  total={10}
                  value={itemsPerPage}
                  handleChange={handleItemsPerPageChange}
                />
              </div>
            </div>
            <Divider></Divider>
            <Table tickets={tickets} isLoading={isLoading} users={users} updateMany={updateMany} filters={filters}/>
            {!isLoading && (
              <Pagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                total={total}
                updateFilter={handleCurrentPage}
              />
            )}
          </div>
        </section>
      </main>
    </CanViewPage>
  );
}

const mapStateToProps = (reduxState) => ({
  tickets: reduxState.tickets.list,
  filters: reduxState.tickets.filters,
  totals: reduxState.tickets.totals, // all totals for individual category/status/tab
  total: reduxState.tickets.total, // total items with current filters applied
  categories: reduxState.tickets.categories,
  isLoading: reduxState.tickets.isLoading,
  searchState: reduxState.tickets.searchState,
  // users: reduxState.users.userlist,

});

export default connect(mapStateToProps, {
  updateFilters,
  getTickets,
  getTotals,
  getTicketCategories,
  createTicketCategory,
  updateTicketCategory,
  setSearchState,
  updateMany
})(ListTicket);
