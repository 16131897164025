import React, { useState, useEffect } from "react";
import {

  Label,
  Dimmer,
  Loader,
  Button,
  Dropdown,
  Header,
} from "semantic-ui-react";
import {DatePicker} from 'rsuite'
import Moment from "react-moment";
import Modal from "react-responsive-modal";

import { createOptions } from "../../../helpers/createOptions";
import UserFilter from "../../Filters/UserFilter";
import { statusColor, priorityColor } from "../ListTicket/Table";
import Participants from "../CreateTicket/Participants";

function Sidebar(props) {
  const {
    // subject,
    _id,
    // number,
    createdAt,
    updatedAt,
    status,
    callcenter,
    priority,
    category = {},
    subcategory,
    openBy = {},
    assignedTo = {_id:null,username:null},
    participants = [],
    isLoading,
    canEdit,
    ticketId,
    updateTicket,
    categories = [],
  } = props;

  //  subcategories dropdown options
  const [subcategoriesOption, setSubcategoriesOption] = useState([]);
  useEffect(() => {
    if (category._id && categories.length) {
      const options = categories
        .find((c) => c._id === category._id)
        .subcategories.map((subcategory, i) => ({
          key: i,
          value: subcategory,
          text: subcategory,
        }));
      setSubcategoriesOption(options);
    }
  }, [category._id, categories]);

  // edit
  const [isEditOn, setEditOn] = useState(null);
  const [update, setUpdate] = useState({callcenter:{status:''}});
  const [updatedParticipants, setUpdatedParticipants] = useState([]);

  // console.log({ update, updatedParticipants });

  const handleEditOn = (field, initialValue) => {
    // console.log({ field, initialValue });
    if (field === "participants")
      setUpdatedParticipants([...initialValue.map(({ _id }) => _id)]);
    setEditOn(field);
    if (field === "category")
      setUpdate((prev) => ({ ...prev, [field]: initialValue._id }));
    else setUpdate((prev) => ({ ...prev, [field]: initialValue }));
  };

  const handleEditOff = () => {
    // if (field === "participants") setUpdatedParticipants([]);
    setEditOn(null);
  };

  const handleFieldChange = (e, { name, value }) =>
    setUpdate({ [name]: value });

  const handleUpdatesSave = () => {
    if (isEditOn === "participants")
      updateTicket({
        id: ticketId,
        query: { participants: updatedParticipants },
      });
    // if category is change clear previous subcategory as well
    else if (isEditOn === "category" && category._id !== update.category)
      updateTicket({
        id: ticketId,
        query: { ...update, subcategory: "" },
      });
    else updateTicket({ id: ticketId, query: update });
    setEditOn(null);
    setUpdate({ participants: [] });
  };

  const handleParticipantsEditOn = (field, initialValue) => {
    setUpdatedParticipants([...initialValue.map(({ _id }) => _id)]);
    setEditOn(field);
  };
  const handleParticipantChange = (e, { value }) =>
    setUpdatedParticipants((prev) => [...prev, value]);
  const deleteParticipant = (id) =>
    setUpdatedParticipants((prev) => prev.filter((p) => p !== id));

  if (isLoading)
    return (
      <div
        style={{ position: "relative", height: "70vh" }}
        className="ticket-list"
      >
        <Dimmer active inverted>
          <Loader inverted size="medium">
            Loading
          </Loader>
        </Dimmer>
      </div>
    );

  return (
    // <div>
    <div className="aside-menu ticket-edit-aside">
        <Label size="small" basic color={statusColor[status]}>
            {status}
          </Label>
          <div className="ticket-edit-aside__section">
              <span className="ticket-edit-aside__label ">Status:</span>
              <EditableField
                    name="callcenter.status"
                    value={callcenter?callcenter.status:''}
                    label="Status"
                    handleUpdatesSave={handleUpdatesSave}
                    handleEditOff={handleEditOff}
                    handleEditOn={handleEditOn}
                    isEditOn={isEditOn}
                    canEdit={true}
                    editComponent={
                      <Dropdown
                        selection
                        search
                        selectOnBlur={false}
                        closeOnChange={true}
                        fluid
                        style={{ width: "100%" }}
                        name="callcenter.status"
                        value={update.status}
                        onChange={handleFieldChange}
                        options={["New",'Email',"In progress","Offer sent: no respense","Offer sent: rejected","Call later","Not interested","Contract:sent","Contract: signed"].map((p) => ({
                          key: p,
                          value: p,
                          text: p,
                        }))}
                      />
                    }
                    nonEditComponent={
                      <Label size="mini" color={priorityColor[priority]}>
                        {callcenter?callcenter.status:''}
                      </Label>
                    }
                  ></EditableField>
            </div>
            <div className="ticket-edit-aside__section">
              <span className="ticket-edit-aside__label ">Call later:</span>
             <DatePicker
                format={"DD-MM-YYYY HH:mm"}
                
                value={callcenter?callcenter.callLater:''}
                onChange ={(date)=>  updateTicket({
                                        id: ticketId,
                                        query: { 'callcenter.callLater':date},
                                      })}
                ></DatePicker>
            </div>

      <div className="ticket-edit-aside__section">
        
        <span className="ticket-edit-aside__label">Number:</span>
        <span className="bold--text large--text">
          {_id && _id.substring(0, 6)}
        </span>

        <span className="ticket-edit-aside__label ">Priority:</span>
        <EditableField
          name="priority"
          value={priority}
          label="Priority"
          handleUpdatesSave={handleUpdatesSave}
          handleEditOff={handleEditOff}
          handleEditOn={handleEditOn}
          isEditOn={isEditOn}
          canEdit={canEdit}
          editComponent={
            <Dropdown
              selection
              search
              selectOnBlur={false}
              closeOnChange={true}
              fluid
              style={{ width: "100%" }}
              name="priority"
              value={update.priority}
              onChange={handleFieldChange}
              options={["High", "Medium", "Low"].map((p) => ({
                key: p,
                value: p,
                text: p,
              }))}
            />
          }
          nonEditComponent={
            <Label size="mini" color={priorityColor[priority]}>
              {priority}
            </Label>
          }
        ></EditableField>
      </div>
      {/* <Divider /> */}

      <div className="ticket-edit-aside__section">
      
        <span className="ticket-edit-aside__label">Created at:</span>
        <Moment format="DD-MM-YY HH:mm">{createdAt}</Moment>
        <span className="ticket-edit-aside__label">Updated at:</span>
        <Moment format="DD-MM-YY HH:mm">{updatedAt}</Moment>
      </div>
      {/* <Divider /> */}

      <div className="ticket-edit-aside__section">
        <span className="ticket-edit-aside__label">Category:</span>
        <EditableField
          name="category"
          value={category}
          label="Category"
          handleUpdatesSave={handleUpdatesSave}
          handleEditOff={handleEditOff}
          handleEditOn={handleEditOn}
          isEditOn={isEditOn}
          canEdit={canEdit}
          editComponent={
            <Dropdown
              selection
              search
              selectOnBlur={false}
              closeOnChange={true}
              fluid
              style={{ width: "100%" }}
              name="category"
              value={update.category}
              onChange={handleFieldChange}
              options={createOptions(categories)}
            />
          }
          nonEditComponent={<Label size="large">{category.name}</Label>}
        ></EditableField>

        <span className="ticket-edit-aside__label">Subcategory:</span>
        <EditableField
          name="subcategory"
          value={subcategory}
          label="Subcategory"
          handleUpdatesSave={handleUpdatesSave}
          handleEditOff={handleEditOff}
          handleEditOn={handleEditOn}
          isEditOn={isEditOn}
          canEdit={canEdit}
          editComponent={
            <Dropdown
              fluid
              style={{ width: "100%" }}
              placeholder="subcategory..."
              disabled={!category}
              name="subcategory"
              value={update.subcategory}
              selection
              search
              selectOnBlur={false}
              closeOnChange={true}
              onChange={handleFieldChange}
              options={subcategoriesOption}
            />
          }
          nonEditComponent={
            <span className="bold--text grey--text small--text">
              {subcategory}
            </span>
          }
        />
      </div>
      {/* <Divider /> */}

      <div className="ticket-edit-aside__section">
        <span className="ticket-edit-aside__label">Participants:</span>
        <EditableField
          name="participants"
          value={participants}
          label="Participants"
          handleUpdatesSave={handleUpdatesSave}
          handleEditOn={handleParticipantsEditOn}
          handleEditOff={handleEditOff}
          isEditOn={isEditOn}
          canEdit={canEdit}
          editComponent={
            <Participants
              value={updatedParticipants}
              handleChange={handleParticipantChange}
              deleteParticipant={deleteParticipant}
              style={{ width: "100%" }}
              fluid
            />
          }
          nonEditComponent={
            <span>
              {participants.map(({ username }) => username).join(", ")}
            </span>
          }
        ></EditableField>

        <span className="ticket-edit-aside__label">Open by:</span>
        <span>{openBy.username}</span>

        <span className="ticket-edit-aside__label">Assigned user:</span>
        <UserFilter
              style={{ width: "100%" }}
              fluid
              name="assignedTo"
              value={assignedTo&&assignedTo._id?assignedTo._id:''}
              handleChange={ (e,{value})=>updateTicket({
                id: ticketId,
                query: { assignedTo: value },
              })}
            />
        {/* <EditableField
          name="assignedTo"
          value={assignedTo._id}
          label="Assigned user"
          handleUpdatesSave={handleUpdatesSave}
          handleEditOn={handleEditOn}
          handleEditOff={handleEditOff}
          isEditOn={isEditOn}
          canEdit={canEdit}
          editComponent={
            <UserFilter
              style={{ width: "100%" }}
              fluid
              name="assignedTo"
              value={update.assignedTo}
              handleChange={handleFieldChange}
            />
          }
          nonEditComponent={<span>{assignedTo.username}</span>}
        ></EditableField> */}
      </div>
    </div>
    // </div>
  );
}

export default Sidebar;

const EditableField = ({
  name,
  value,
  label,
  handleUpdatesSave,
  handleEditOn,
  handleEditOff,
  isEditOn,
  canEdit = false,
  editComponent,
  nonEditComponent,
}) => (
  <>
    <Modal
      open={isEditOn === name}
      showCloseIcon={true}
      closeOnEsc={true}
      onClose={handleEditOff}
      center
      classNames={{
        modal: "",
      }}
    >
      <div className="ticket-edit-modal">
        <Header as="h4">Edit</Header>
        <label style={{ paddingBottom: "0.3em" }}>{label}</label>:
        <br />
        {editComponent}
        <br />
        <Button.Group className="mt--2">
          <Button
            compact
            size="mini"
            icon="times"
            content="Cancel"
            onClick={handleEditOff}
          />
          <Button
            primary
            compact
            size="mini"
            icon="check"
            content="Update"
            onClick={handleUpdatesSave}
          />
        </Button.Group>
      </div>
    </Modal>

    <div className="flex--between vertical--center ticket-edit-aside__editable">
      {nonEditComponent}
      {canEdit && (
        <Button
          className="ticket-edit-btn"
          // style={{ boxShadow: "none" }}
          basic
          compact
          size="mini"
          icon="pencil"
          onClick={() => handleEditOn(name, value)}
        />
      )}
    </div>
  </>
);
