import React from "react";
import { Form } from "semantic-ui-react";
import DatePicker from "react-datepicker";

function DateFilter({
  dateFrom,
  dateTo,
  handleDateFromChange,
  handleDateToChange,
  style = {}
}) {
  
  // console.log(dateFrom , dateTo)

  return (
    <Form className="filters__part filters__part_last" style={style}>
      <div className="filters__input">
        <DatePicker
          selected={dateFrom}
          isClearable={true}
          dateFormat="MMMM d, yyyy"
          onChange={handleDateFromChange}
          placeholderText="date from..."
        ></DatePicker>
      </div>
      <div className="filters__input" style={{ marginRight: "0" }}>
        <DatePicker
          selected={dateTo}
          isClearable={true}
          dateFormat="MMMM d, yyyy"
          onChange={handleDateToChange}
          placeholderText="date to..."
        ></DatePicker>
      </div>
    </Form>
  );
}

export default DateFilter;
