import React, {  useState } from "react";
import { Header, Input, Button, Divider } from "semantic-ui-react";

import DepartmentFilter from "../../Filters/DepartmentFilter";

function CategoryModal({
  closeModal,
  isEditModeOn,
  initFormValues,
  createTicketCategory,
  updateTicketCategory,
}) {
  const [formValues, setFormValues] = useState(initFormValues);
  const { name, subcategories = [], newSubcategory, department } = formValues;

  const handleChange = (e, { value, name }) =>
    setFormValues((prev) => ({ ...prev, [name]: value }));

  const handleAddSubcategory = (e) => {
    e.preventDefault();
    setFormValues((prev) => ({
      ...prev,
      subcategories: [...subcategories, newSubcategory],
      newSubcategory: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    isEditModeOn
      ? updateTicketCategory({
          id: initFormValues._id,
          query: { name, subcategories, department },
        })
      : createTicketCategory({ name, subcategories, department });
    closeModal();
  };
  const handleCancel = (e) => {
    e.preventDefault();
    closeModal();
  };

  return (
    <section className="category-modal">
      <Header as="h4">{isEditModeOn ? "Edit" : "New"} Ticket</Header>
      <Divider />

      <form autoComplete="off" onSubmit={handleSubmit} className="flex--column">
        <label>Category name:</label>
        <Input type="text" name="name" value={name} onChange={handleChange} />

        <label>Select department:</label>
        <DepartmentFilter
          name="department"
          value={department}
          handleChange={handleChange}
        />

        <label className="mt--1">Subcategories:</label>
        {subcategories.length > 0 && (
          <ul className="category-modal__subcategories-list">
            {subcategories.map((subcategory) => (
              <li>{subcategory}</li>
            ))}
          </ul>
        )}
        <Input
          type="text"
          name="newSubcategory"
          value={newSubcategory}
          onChange={handleChange}
          action={{
            icon: "plus",
            onClick: handleAddSubcategory,
            disabled: !newSubcategory,
          }}
        />

        <div className="flex--right mt--2">
          <Button content="Cancel" onClick={handleCancel} />
          <Button primary content="Save" onClick={handleSubmit} />
        </div>
      </form>
    </section>
  );
}

export default CategoryModal;
